import config from '@/api/config'
export default {
    //正则表达替换富文本编辑器html的图片路径
    toReplaceEditorImgURL(str) {
        /**
         * 1，匹配出图片img标签（即匹配出所有图片），过滤其他不需要的字符
         * 2.从匹配出来的结果（img标签中）循环匹配出图片地址（即src属性）
         */

        var imgReg = /<img.*?(?:>|\/>)/gi;      //正则匹配图片（g表示匹配所有结果i表示区分大小写）
        var videoReg = /<video [^>]*src=['"]([^'"]+)[^>]*>/g;   //正则匹配视频标签（g表示匹配所有结果i表示区分大小写）
        var srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;   //正则匹配src属性
    
        var arr = str.match(imgReg);
        var videoArr = str.match(videoReg);

        if(arr!=null){
            for (var i = 0; i < arr.length; i++) {
                var src = arr[i].match(srcReg);
                //获取图片地址
                if (src!= null && src[1]) {
                    /**
                    * @修改人：YangLei
                    * @修改日期：2022-6-20 18:45
                    * @修改内容：控制文章内图片宽度
                     * */
                    str = str.replace(src[1]+"\"", config.server + src[1]+"\" style='width:100%'");
                }
            }
        }
        
        if(videoArr!=null){
            console.log(videoArr.lengt)
            for (var k = 0; k < videoArr.length; k++) {
                var videoSrc = videoArr[k].match(srcReg);
                //获取视频地址
                if (videoSrc[1]) {
                    str = str.replace(videoSrc[1], config.server + videoSrc[1]);
                }
            }
        }
        return str;
    },

    /**
     * 复制网络图片重复显示
     */
     replaceDuplicatePicture(str){
        var imgReg = /<img.*?(?:>|\/>)/gi;      //正则匹配图片（g表示匹配所有结果i表示区分大小写）
        var srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;   //正则匹配src属性
        var arr = str.match(imgReg);
        
        if(arr!=null){
            for (var i = 0; i < arr.length; i++) {
                var src = arr[i].match(srcReg);

                //获取图片地址
                if (src[1] && src[1].includes(config.server)) {
                    str = str.replace(src[1], config.server + src[1]);
                } else {
                    str = str.replace(src[1], '');
                }
           
            }
        }
        return str;
     }
}