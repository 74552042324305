<template>
  <el-container>
    <!--  顶部信息-->
    <el-header class="front-header">
      <frontHead></frontHead>
    </el-header>
    <el-main class="front-main">
      <div class="main-box">
        <!-- 导航-->
        <el-breadcrumb separator="/" style="margin-bottom: 20px;font-size: 16px;">
          <el-breadcrumb-item :to="{ path: '/' }"><el-icon class="el-icon-location" size="16"></el-icon>&nbsp;首页</el-breadcrumb-item>
          <el-breadcrumb-item class="top-channel">{{
              topChannelName
            }}
          </el-breadcrumb-item>
          <el-breadcrumb-item v-if="topChannelId!=curChannelId">{{ subChannelName }}</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 栏目介绍-->
        <el-row v-show="channel.single">
          <el-col :span="24">
            <el-card class="box-card">
              <template #header v-if="subChannelList.length>0">
                <div class="sub-channel">
                  <el-link :type="curChannelId==item.id?'primary':''" @click="chooseSubChannel(item.id,item.name,item.single)" v-for="item in subChannelList"
                           :key="item.id">{{ item.name }}
                  </el-link>
                </div>
              </template>
              <!-- 文章内容-->
              <div class="introduce" v-if="channel.content!=''">
                  <div v-html="channel.content"></div>
              </div>
              <el-empty description="没有数据" v-else/>
            </el-card>
          </el-col>
        </el-row>
        <!-- 最新发布-->
        <el-row v-show="!channel.single">
          <el-col :span="24">
            <el-card class="box-card">
              <template #header v-if="subChannelList.length>0">
                <div class="sub-channel" v-if="subChannelList.length>0">
                  <el-link :type="curChannelId==item.id?'primary':''"  @click="chooseSubChannel(item.id,item.name,item.single)" v-for="item in subChannelList"
                           :key="item.id">{{ item.name }}
                  </el-link>
                </div>
              </template>
              <div v-if="contentList.length>0">
                <!-- 产品中心展示列表-->
                <div v-if="topChannelId==1">
                  <div v-for="(content,index) in contentList" :key="index" class="item-product"  @click="toContentDetail(content.id)">
                    <img v-if="!content.imageError" :src="serverURL+content.thumbnail" @error="content.imageError=true">
                    <img v-else src="../../assets/images/front/no-pic-mbzs.png">
                    <el-link :underline="false">{{strUtil.cutTitle(content.title,12)}}</el-link>
                  </div>
                </div>
                <!-- 常见问题展示列表-->
                <div v-else-if="curChannelId==4">
                  <div v-for="(content,index) in contentList" :key="index" class="problem-item"  @click="toContentDetail(content.id)">
                    <div class="problem-title">
                      <span>Q</span>{{strUtil.cutTitle(content.title,70)}}
                    </div>
                    <div class="problem-content">
                      <div class="content" v-html="strUtil.cutTitle(content.content,160)"></div>
                    </div>
                  </div>
                </div>
                <!-- 通用-->
                <div v-for="(content,index) in contentList" :key="index" class="item" @click="toContentDetail(content.id)" v-else>
                  <div class="item-left">
                    <img v-if="!content.imageError" :src="serverURL+content.thumbnail" @error="content.imageError=true">
                    <!-- 判断是否有标题图，没有则显示no-pic.png -->
                    <img v-else src="../../assets/images/front/no-pic-pc.png">
                  </div>
                  <div class="item-right">
                    <span class="title">{{ strUtil.cutTitle(content.title, 50) }}</span>
<!--                    <span>发布时间：{{ content.time.substring(0, 10) }}</span>-->
<!--                    <span>浏览：{{ content.clicks ? content.clicks : '0' }}</span>-->
                    <span v-html="strUtil.cutTitle(content.content, 180)"></span>
                  </div>
                </div>
              </div>
              <el-empty description="没有数据" v-else/>
              <!--分页-->
              <div class="pagination-block" v-show="!channel.single&&total/pageSize>1">
                <el-pagination
                    v-model:currentPage="curPage"
                    :page-sizes="[15, 50, 100, 200]"
                    :page-size="pageSize"
                    layout="prev, pager, next"
                    :total="total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>

    </el-main>
    <!--  底部信息-->
    <el-footer class="front-footer">
      <frontFooter></frontFooter>
    </el-footer>
  </el-container>
</template>

<script>

import frontHead from "@/views/front/head";
import frontFooter from "@/views/front/footer";
import config from '@/api/config'
import contentApi from "@/api/info/content";
import channelApi from "@/api/info/channel"
import replaceEditorImgURL from '@/utils/replaceEditorImgURL'
import strUtil from "@/utils/strUtil";
import dateUtil from "@/utils/dateUtil";
import variableApi from "@/api/system/variable";

export default {
  name: "index",
  components: {
    frontHead, frontFooter
  },
  data() {
    return {
      topChannelId: '',
      topChannelName: '',
      subChannelName: '',
      curChannelId: '',
      curChannelName: '',
      curChannelSingle: false,
      curPage: 1,
      pageSize: 8,
      serverURL:'',
      total: 0,
      channel: {
        single: false,
        name: '',
        content: ''
      },
      contentList: [],
      subChannelList: [],
      strUtil,
      dateUtil,
      mapCenter:[],// 地图中心点坐标
      cemetery:'',// 墓园名称
    }
  },
  methods: {
    // 加载地图
    loadMap(){
      // 请求获取坐标和名称
      variableApi.queryVarNumVariable({"varNum":'location'}).then(response=>{
        if(response.data.code==1){
          this.mapCenter=JSON.parse(response.data.data.location);
          variableApi.queryVarNumVariable({"varNum":'cemetery'}).then(response=>{
            if(response.data.code==1){
              this.cemetery=response.data.data.cemetery;
              // 渲染地图
              var map = new AMap.Map("mapContainer", {
                zoom: 16,
                center:this.mapCenter
              });
              // 添加标记点
              var marker = new AMap.Marker({
                map:map,
                position:this.mapCenter,
                label: {
                  offset: new AMap.Pixel(-30, -30),//修改label相对于maker的位置
                  content: "点击导航"
                }
              })
              // 标记点事件
              let cemeteryName=this.cemetery;
              let lngLat=this.mapCenter;
              marker.on('click',function(e){
                let url="https://gaode.com/regeo?lng=[lng]&lat=[lat]&name=[name]&adcode=";
                const aLink = document.createElement('a')
                aLink.style.display = 'none'
                aLink.target="_blank";
                url=url.replaceAll('[lng]',lngLat[0]).replaceAll('[lat]',lngLat[1]).replaceAll('[name]',encodeURI(cemeteryName))
                aLink.href = url
                document.body.appendChild(aLink)
                aLink.click()
                URL.revokeObjectURL(aLink.href);//清除引用
                document.body.removeChild(aLink);

                /*marker.markOnAMAP({
                  name:cemeteryName,
                  position:marker.getPosition()
                })*/
              })
            }
          })
        }
      })
    },
    //获取内容列表
    getContentDataFromServe(){
      this.contentList=[];
      contentApi.pageContentByChannelId({
        pageSize: this.pageSize,
        curPage: this.curPage,
        channelId: this.curChannelId
      }).then((res) => {
        this.contentList = res.data.data.records;
        this.total = res.data.data.total;
        //返回顶部
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      })
    },
    // 加载当前页的数据
    loadData(){
      this.childChannel();// 子栏目
      this.queryChannelIntroduction();// 栏目介绍
      if(!this.curChannelSingle){
        this.getContentDataFromServe();
      }
      // 如果是联系我们，加载地图
      /*if(this.curChannelName=='联系我们'){
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://webapi.amap.com/maps?v=2.0&key=c30ecf7cb88987713f3fdff3948d6952';
        document.body.appendChild(script);
        script.onload = () => {
          this.loadMap();
        };
      }*/
    },
    // 点击导航的栏目
    toTopChannel(){
      this.subChannelName='';
      this.curChannelId=this.topChannelId;
      this.curChannelName=this.topChannelName;
      this.topChannelId=this.topChannelId;
      this.topChannelName=this.topChannelName;
      this.loadData();
    },
    // 点击子栏目
    chooseSubChannel(id,name,single) {
      this.subChannelName = name;
      this.curChannelId=id;
      this.curChannelName=name;
      this.curChannelSingle=single;
      this.loadData();
    },
    //根据栏目id查询栏目介绍
    queryChannelIntroduction() {
      channelApi.queryChannelContent(this.curChannelId).then(res => {
        this.channel.name = res.data.data.name;
        this.channel.single = res.data.data.single;
        if (res.data.data.content != null) {
          this.channel.content = replaceEditorImgURL.toReplaceEditorImgURL(res.data.data.content);
        }
      })
    },
    // 跳转到文章详情
    toContentDetail(contentId) {
      this.contentId = contentId;
      this.$router.push({
        path: '/content',
        query: {
          contentId: contentId,
          channelName: this.curChannelName,
          channelId: this.curChannelId,
          parentChannelId: this.topChannelId,
          parentChannelName: this.topChannelName,
        }
      });
    },
    //获取子栏目
    childChannel(){
        channelApi.queryChildChannel(this.topChannelId)
                .then((response) => {
          this.subChannelList = response.data.data;
      });
    },
    //每页大小
    handleSizeChange(val) {
      this.pageSize=val;
      this.curPage=1;
      this.loadData();
    },
    //当前页
    handleCurrentChange(val) {
      this.curPage=val;
      this.loadData();
    },
  },
  mounted() {
    this.topChannelId = this.$route.query.parentChannelId;
    this.topChannelName = this.$route.query.parentChannelName;
    this.curChannelId=this.$route.query.channelId;// 初始化时，当前栏目id为传递过来的值
    this.curChannelName=this.$route.query.channelName;// 初始化时，当前栏目id为传递过来的值
    if(this.topChannelId!=this.curChannelId){
      this.subChannelName=this.curChannelName;
    }
    this.subChannelList=[];
    this.serverURL = config.server;
    this.loadData();
  },
  watch: {
    // 利用watch方法检测路由变化
    '$route': function (to, from) {
      if(to.path=='/channel'){
        // 拿到目标参数 to.query.id 去再次请求数据接口
        this.topChannelId=to.query.parentChannelId;
        this.topChannelName=to.query.parentChannelName;
        this.curChannelId=to.query.channelId;
        this.curChannelName=to.query.channelName;
        if(this.topChannelId!=this.curChannelId){
          this.subChannelName=this.curChannelName;
        }
        this.loadData();
      }
    }
  }
}
</script>

<style scoped>
.main-box{
  width: 1240px;
  margin: 0 auto;
  color: #FFFFFF;
  padding: 20px;
  background-color: #000000;
  min-height: 1000px;
}
/deep/.el-breadcrumb__inner{
  background-color: #000000;
  color: #FFFFFF
}
.top-channel{
  font-weight: 700;
  /*cursor: pointer;*/
}

.box-card{
  background-color: #000000;
  border: none;
  color: #FFFFFF;
}
/deep/.el-card__header{
  /*border-bottom: none;*/
  padding: 20px 0;
}
/deep/.el-link.el-link--default{
  color: #FFFFFF;
  --el-link-font-color:#ffffff;
}

/deep/.el-card__body{
  padding: 20px 0;
}

.item {
  margin-bottom: 18px;
}

.badge {
  padding-right: 10px;
}

.pagination-block {
  text-align: center;
  padding-top: 10px;
}
/deep/.el-pagination .btn-prev {
  background-color: #000;
  color: #fff;
  padding-right: 12px;
  font-size: 16px;
  border: #8c939d solid 1px;
  border-radius: 5px;
}
/deep/.el-pagination .btn-next{
  background-color: #000;
  color: #fff;
  font-size: 16px;
  border: #8c939d solid 1px;
  border-radius: 5px;
}
/deep/.el-pager li{
  background-color: #000;
  color: #fff;
  font-size: 16px;
}

/deep/.el-pager li.active{
  color: #409eff;
}

.el-row {
  margin-bottom: 15px;
}

.card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #409eff;
  font-size: 18px;
}

.introduce {
  /*text-align: center;*/
}

.introduce img {
  width: 98%;
  height: 800px;
}

/*子栏目*/
.sub-channel .el-link {
  font-size: 16px;
  margin-right: 20px;
}

.sub-channel img {
  width: 25px;
  float: left;
  margin-right: 10px;
}

/*列表样式*/
.item * {
  font-size: 16px;
  color: #FFFFFF;
}

.item {
  height: 200px;
  border: #e9e9eb solid 1px;
  box-sizing: border-box; /*内边框*/
  padding: 10px;
  overflow: hidden;
}

.item:hover {
  border: #409eff solid 1px;
  cursor: pointer;
  box-sizing: border-box; /*内边框*/
}

.item-left {
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 250px;
  overflow: hidden;
}
.item-left img {
  height: 100%;
  vertical-align: middle;
  /*object-fit: cover;*/
}

.item-right {
  display: inline-block;
  width: calc(100% - 275px);
  margin-left: 25px;
  vertical-align: top;
}

.item-right .title{
  font-size: 20px;
  font-weight: 700;
  color: #FFFFFF;
}

.item-right span {
  color: #8c939d;
  font-size: 16px;
  display: block;
  /*padding: 10px 5px;*/
}
/*常见问题*/
.problem-item{
  margin-bottom: 15px;
  border: #FFFFFF solid 1px;
}
.problem-item:hover{
  /*border: #409eff solid 1px;*/
  background-color: #8c939d;
  cursor: pointer;
}
.problem-title{
  line-height: 40px;
  border-bottom: #FFFFFF solid 1px;
}
.problem-title span{
  display: inline-block;
  text-align: center;
  color: #696969;
  width: 40px;
  background-color: #FFFFFF;
  margin-right: 10px;
}
.problem-content{
  line-height: 40px;
}
.problem-content .content{
  padding: 0 10px;
}
/*产品中心*/
.item-product{
  display: inline-block;
  text-align: center;
  margin: 0 16px 16px 0px;
  overflow: hidden;
}
.item-product .el-link{
  display: block;
  font-size: 16px;
  color: #FFFFFF
}
.item-product img{
  height: 420px;
  width: 280px;
  cursor: pointer;
}
.item-product img:hover{
  box-sizing: border-box;
  border: #409eff solid 2px;
}
</style>