export default {
    // 是否当天
    isNew(date){
        return this.getCurrentDate() == date.substring(0, 10);
    },
    // 获取当前日期yyyy-MM-dd
    getCurrentDate() {
        var date = new Date();
        return this.formatDate(date)
    },
    // 计算n年+n月后的日期
    addYearMonth(dateStr, addYear, addMonth) {
        var date = new Date(dateStr);
        if (addYear != undefined) {
            date.setFullYear(date.getFullYear() + addYear)
        }
        if (addMonth != undefined) {
            date.setMonth(date.getMonth() + addMonth)
        }
        return this.formatDate(date)
    },
    // 格式化日期yyyy-MM-dd
    formatDate(date) {
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        return '' + year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day)
    },

    // 格式化日期yyyy-MM-dd HH:MM:SS
    formatDateTime(date) {
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        month = month < 10 ? ('0' + month) : month;
        var day = date.getDate();
        day = day < 10 ? ('0' + day) : day;
        var hour = date.getHours();
        hour = hour < 10 ? ('0' + hour) : hour;
        var minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        var second=date.getSeconds();
        second=second < 10 ? ('0' + second) : second;
        return year + '-' + month + '-' + day+' '+hour+':'+minute+':'+second;
    },

    // string转为日期格式
    parseDate(dateString) {
        var isoExp = /^\s*(\d{4})-(\d\d)-(\d\d)\s*$/,
            date = new Date(NaN), month,
            parts = isoExp.exec(dateString);

        if (parts) {
            month = +parts[2];
            date.setFullYear(parts[1], month - 1, parts[3]);
            if (month != date.getMonth() + 1) {
                date.setTime(NaN);
            }
        }
        return date;
    },
    // 获取格式HH:ss
    getTimeStr(date){
        var hour = date.getHours();
        var minute = date.getMinutes();
        return (hour < 10 ? '0' + hour : hour) + ':' + (minute < 10 ? '0' + minute : minute)
    },

    // 日期年份是否超出最大年份
    overLimitYear(dateStr,year){
        // 获取当年年份
        let nowDate=new Date();
        let nowYear = nowDate.getFullYear();
        let endYear=dateStr.split('-')[0];
        let maxYear=nowYear+year;
        if(endYear>maxYear){
            return maxYear;
        }
        return '';
    }
}